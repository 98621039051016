import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ResourceService } from '../service/resource.service';

@Component({
    selector: 'thanks',
    templateUrl: './thanks.component.html'
})
export class ThanksComponent implements OnInit {
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private resourceService: ResourceService) { };
    public docTitle: string = '';
    public productType: string = '';
    public resultTag: string = '';
    public scenario: string = '';
    public resultMsg: string = '';
    public appLabel: any = {};
    public successTitle: string = 'Success!';
    public errorTitle: string = 'Error';
    public productMessageCatalog: any = {
        signUpSuccess : "Thank you for registering for {{docTitle}}. Please check your email to complete your registration process. You may safely close this window.",
        addProductSuccess : "Your subscription has been added. Redirecting to Dashboard.",
        upgradeSuccess :"Your subscription has been successfully updated. Redirecting to dashboard.",
        nextAction: "hold"
    };

    ngOnInit(): void {
        this.productType = this.activatedRoute.snapshot.paramMap.get('productType');
        const externalProductList = ['geotax', 'sdm', 'mipro', 'geocoding', 'software-apis'];
        if(externalProductList.indexOf(this.productType) < 0){
            window.location.href = '/undefined';
        }
        switch (this.productType) {
            case 'geocoding':
                this.docTitle = 'MapMarker';
                break;
            case 'sdm':
                this.docTitle = 'Precisely Data Experience';
                break;
            case 'geotax':
                this.docTitle = 'GeoTAX';
                break;
            case 'mipro':
                this.docTitle = 'MapInfo Pro';
                break;
            default:
                this.docTitle = 'Precisely APIs';
        }
        this.titleService.setTitle(this.docTitle);
        this.activatedRoute.queryParams.subscribe(
            params => {
                if(params.resultTag){
                    this.resultTag = params.resultTag;
                }
                if(params.scenario){
                    this.scenario = params.scenario;
                }
            }
        );
        if(this.resultTag === 'true'){
            if(this.productType !== 'mipro'){
                this.resultMsg = this.productMessageCatalog[this.scenario].replace('{{docTitle}}', this.docTitle);
            }
            else{
                let appLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
                if(appLang === null){
                    appLang = 'en-US';
                }
                this.resourceService.getAppLanguage(appLang).subscribe(
                    success => {
                        this.appLabel = success;
                        this.successTitle = this.appLabel.TH_Success;
                        if(this.scenario === 'signUpSuccess'){
                            this.resultMsg = this.appLabel.TH_Create_Success_MiPro
                        }
                        if(this.scenario === 'addProductSuccess'){
                            this.resultMsg = this.appLabel.TH_AddProduct_Success;
                        }                        
                        if(this.scenario === 'upgradeSuccess'){
                            this.resultMsg = this.appLabel.TH_Update_Success;
                        }
                    }
                );
            }
        }
        else{
            if(this.productType !== 'mipro'){
                this.resultMsg = "We're having issue in processing your request. Please try again.";
            }
            else{
                let appLang = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
                if(appLang === null){
                    appLang = 'en-US';
                }
                this.resourceService.getAppLanguage(appLang).subscribe(
                    success => {
                        this.appLabel = success;
                        this.errorTitle = this.appLabel.TH_Failure;
                        this.resultMsg = this.appLabel.SUP_UnexpectedError;
                    }
                );
            }
        }
        sessionStorage.removeItem('planType');
        sessionStorage.removeItem('userData');
        setTimeout(() => {
            if(this.scenario === 'upgradeSuccess' || this.scenario === 'addProductSuccess'){
                window.location.href = '/signin/'+this.productType;
            }
        }, 3000);
    }
}
