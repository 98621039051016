import { Component} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pagenotfound',
  templateUrl: './pagenotfound.component.html'
})
export class PagenotfoundComponent {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { };
}
