import { Component, Input, Output, EventEmitter } from "@angular/core";
import { GeosearchService } from './geosearch.service';

@Component({
    selector: 'geosearch',
    templateUrl: './geosearch.component.html',
    styleUrls: ['./geosearch.component.css']
})
export class GeosearchComponent {
    public _showResults: boolean = false;
    public loading: boolean = false;
    public results = {};
    public resultLength: number;
    public selectedIndex: number = -1;
    public requestObj: any = null;
    public searchText_required: boolean = false;
    public searchText_maxlength: boolean = false;
    constructor(private searchLocationsTmp: GeosearchService) { }

    @Input() apiUrl: string = 'https://api.precisely.com/typeahead/v1/locations';
    @Input() minLength: number = 3;
    @Output() callbackEvent = new EventEmitter<any>();

    @Input() maxCandidates: number = 5;
    @Input() latitude: string;
    @Input() longitude: string;
    @Input() searchRadius: string;
    @Input() searchRadiusUnit: string;
	@Input() country: string;
	@Input() areaName1: string;
	@Input() areaName3: string;
	@Input() postCode: string;
    @Input() matchOnAddressNumber: string;
    @Input() searchOnAddressNumber: string;
    @Input() returnAdminAreasOnly: string;
    @Input() includeRangesDetails: number;
    @Input() autoDetectLocation: string;
    @Input() ipAddress: string;

    onQueryUpdate = function () {
        let fullUrl = this.apiUrl + '?maxCandidates=' + this.maxCandidates;
        if(this.latitude){fullUrl += '&latitude=' + this.latitude;}
        if(this.longitude){fullUrl += '&longitude=' + this.longitude;}
        if(this.searchRadius){fullUrl += '&searchRadius=' + this.searchRadius;}
        if(this.searchRadiusUnit){fullUrl += '&searchRadiusUnit=' + this.searchRadiusUnit;}
        if(this.country){fullUrl += '&country=' + this.country;}
        if(this.areaName1){fullUrl += '&areaName1=' + this.areaName1;}
        if(this.areaName3){fullUrl += '&areaName3=' + this.areaName3;}
        if(this.postCode){fullUrl += '&postCode=' + this.postCode;}
        if(this.matchOnAddressNumber){fullUrl += '&matchOnAddressNumber=' + this.matchOnAddressNumber;}
        if(this.searchOnAddressNumber){fullUrl += '&searchOnAddressNumber=' + this.searchOnAddressNumber;}
        if(this.returnAdminAreasOnly){fullUrl += '&returnAdminAreasOnly=' + this.returnAdminAreasOnly;}
        if(this.includeRangesDetails){fullUrl += '&includeRangesDetails=' + this.includeRangesDetails;}
        if(this.autoDetectLocation){fullUrl += '&autoDetectLocation=' + this.autoDetectLocation;}
        if(this.ipAddress){fullUrl += '&ipAddress=' + this.ipAddress;}

        if(this.searchText === ""){
            this.searchText_required = true;
            this.searchText_maxlength = false;
        }
        else if(this.searchText.length > 100){
            this.searchText_required = false;
            this.searchText_maxlength = true;
        }
        else{
            this.searchText_required = false;
            this.searchText_maxlength = false; 
        }

        if(this.searchText !== "" && this.searchText.length >= this.minLength) {
            fullUrl += '&searchText=' + encodeURIComponent(this.searchText) + '&clientIdentifier=LearnPageSampleDemo';
            this.loading = true;
            if(this.requestObj != null){
                this.requestObj.unsubscribe();
            }
            this.requestObj = this.searchLocationsTmp.getGeosearchLocations(fullUrl).subscribe(
                success => {
                    this.loading = false;
                    if (success.location) {
                        if (success.location.length != 0) {
                            this.results = success;
                            this.resultLength = success.location.length;
                            this._isError = false;
                            this._showResults = true;
                            this.selectedIndex = 0;
                        }
                        else {
                            this._isError = true;
                            this._showResults = false;
                        }
                    }
                    else {
                        this._isError = true;
                        this._showResults = false;
                    }
                },
                error => {
                    this.loading = false
                    this._isError = true;
                    this._showResults = false;
                }
            );
        }
        if (this.searchText.length < this.minLength) {
            this._showResults = false;
        }
    };
    onResultClicked = function (result) {
        if (result.totalUnitCount > 1) {
            this.results.location = [];
            let locArr = [];
            let addrObj = result.address;
            let geometryObj = result.geometry;
            let arrCount = 0;
            for (let i = 0; i < result.ranges.length; i++) {
                for (let j = 0; j < result.ranges[i].units.length; j++) {
                    locArr[arrCount] = {};
                    locArr[arrCount].totalUnitCount = 1;
                    locArr[arrCount].geometry = Object.assign({}, geometryObj);
                    locArr[arrCount].address = Object.assign({}, addrObj);
                    locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                    var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                    locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                    arrCount++;
                }
            }
            this.results.location = locArr;
        }
        else {
            this.searchText = result.address.formattedAddress;
            this._showResults = false;
            this.callbackEvent.emit(result);
        }
    };
    onKeyDown = function (event) {
        if (document.getElementById('geoitemcontainer')) {
            if (event.keyCode === 38) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex > 1) {
                    if (this.addCSS(this.selectedIndex - 1)) {
                        this.selectedIndex = this.selectedIndex - 1;
                        this.removeCSS(indexval);
                        document.getElementById('geoitemcontainer').scrollTop -= 40;
                    }
                }
            }
            if (event.keyCode === 40) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex < this.resultLength) {
                    if (this.addCSS(this.selectedIndex + 1)) {
                        this.selectedIndex = this.selectedIndex + 1;
                        this.removeCSS(indexval);
                        if (this.selectedIndex > 1) {
                            document.getElementById('geoitemcontainer').scrollTop += 40;
                        }
                    }
                }
            }
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('geoitemcontainer').scrollTop = 0;
                var indexval = this.selectedIndex;
                if (indexval > 0) {
                    var result = this.results.location[indexval - 1];
                    if (result.totalUnitCount > 1) {
                        this.results.location = [];
                        var locArr = [];
                        var addrObj = result.address;
                        var geometryObj = result.geometry;
                        var arrCount = 0;
                        this.resultLength = result.totalUnitCount;
                        for (var i = 0; i < result.ranges.length; i++) {
                            for (var j = 0; j < result.ranges[i].units.length; j++) {
                                locArr[arrCount] = {};
                                locArr[arrCount].totalUnitCount = 1;
                                locArr[arrCount].address = Object.assign({}, addrObj);
                                locArr[arrCount].geometry = Object.assign({}, geometryObj);
                                locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                                var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                                locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                                this.removeCSS(this.selectedIndex);
                                this.selectedIndex = 0;
                                arrCount++;
                            }
                        }
                        this.results.location = locArr;
                    }
                    else {
                        this.removeCSS(this.selectedIndex);
                        this.selectedIndex = 0;
                        this.searchText = result.address.formattedAddress;
                        this._showResults = false;
                    }
                }
            }
            if (event.keyCode === 27) {
                this.removeCSS(this.selectedIndex);
                this.selectedIndex = 0;
                this._showResults = false;
                event.preventDefault();
            }
        }
    };
    removeCSS = function (index) {
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item";
        }
    };
    addCSS = function (index) {
        var returnval = false;
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item pb-geo-item-selected";
            return true;
        }
        return returnval;
    };
}
