import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

@Injectable()

export class GeosearchService {
    constructor(private http: HttpClient){}

    getGeosearchLocations(_url:any){
        return this.http.get(_url);
    }
}