import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Injectable()
export class UserService{
    constructor(private http: HttpClient, private cookies: CookieService){}
    checkUserName = function(payload){
        return this.http.post('/api/checkUser', payload);
    };
    verifyAddress = function(payload){
        return this.http.post('/api/validateAddress', payload);
    };
    createNewUser = function(payload){
        return this.http.post('/api/createUser', payload);
    };
    modifySubscription = function(payload, productType){
        return this.http.post('/api/changeSubscription/'+productType, payload);
    };

    async getDpUserCookie() {
      let response: boolean | Object;
      try {
        response = await this.http.get('/api/getDpUser').toPromise()
      } catch(error){
        if(error.status === 404){
          response = false
        }
      }
      return response
    }
    ifUserLoggedIn = async function () {
      let response = await this.getDpUserCookie();
      let userJWT = response.dpUser;
      if (userJWT) {
        return !jwtHelper.isTokenExpired(userJWT);
      } else {
        return false;
      }
    };
    getUserInfo = async function () {
      let response = await this.getDpUserCookie();
      let userJWT = response.dpUser;
      let userDetails = jwtHelper.decodeToken(userJWT);
      userDetails.user.userId = userDetails.user.email;
      return userDetails;
    };
}
