import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ResourceService{
    constructor(private http: HttpClient){}
    getAppLanguage = function(lang){
        return this.http.get('/assets/language?lang='+lang);
    };
    getPmcProductDetails = function(productType){
        return this.http.get('/api/pmc/productDetails/'+productType);
    };
    checkIfPlanIsValid = function(planType, productType){
        return this.http.get('/api/v1/validateplan/'+planType+'/'+productType);
    };
    getresources = function () {
        return this.http.get('/api/getresources');
    };
    getRecurlyConfig = function(productType){
        return this.http.get('/api/recurlyPaymentInfo/'+productType);
    };
    getStates = function(countryName){
        return this.http.get('/api/getStates/'+countryName);
    };
    getCountries = function(){
        return this.http.get('/api/v1/getCountries');
    };
}
